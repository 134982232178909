import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// import "./styles/hamburger.scss";
import "./styles/bootstrap.min.css";
import "./styles/rci.css";
import "./styles/rci_navbar.css";
import "./styles/carousel.css"; // change this to the file path of your overrides
import "./styles/rci_header.css";
import "./styles/rci_sections.css";
import "./styles/rci_info.css";
import "./styles/rci_btn.css";
import "./styles/rci_footer.css";

import "./styles/imports.scss";

import "./styles/pagefind.css";
// import "./styles/style.css";

// import Header from "./components/Header";
// import Footer from "./components/Footer";
// import Hero from "./components/Hero";
import Layout from "./components/Layout";

import Home from "./pages/home/home";
import Classes from "./pages/classes/classes";
import Children from "./pages/children/children";
import PersonalTraining from "./pages/personal_training/pt";
import SelfDefence from "./pages/self_defence/sd";
// import Instructors from './pages/instructors/instructors';
import Courses from "./pages/courses/courses";
import About from "./pages/about/about";
import Contacts from "./pages/contacts/contacts";
import Faqs from "./pages/faqs/faqs";
import Friends from "./pages/friends/friends";
// import NotFoundPage from './pages/NotFoundPage';
import Covid19 from "./pages/covid19/cv";
import Memberships from "./pages/memberships/memberships";
import Policy from "./pages/policy/policy";

const App = () => {
  return (
    <>
      {/* <Header />
      <Hero /> */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="classes" element={<Classes />} />
          <Route path="children" element={<Children />} />
          <Route path="personal_training" element={<PersonalTraining />} />
          <Route path="self_defence" element={<SelfDefence />} />
          {/* <Route path="instructors" element={<Instructors />}/> */}
          <Route path="courses" element={<Courses />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="faqs" element={<Faqs />} />
          <Route path="friends" element={<Friends />} />
          <Route path="covid19" element={<Covid19 />} />
          <Route path="memberships" element={<Memberships />} />
          <Route path="policy" element={<Policy />} />
          {/* <Route path="*" component={NotFoundPage} />         */}
          <Route path="*" element={<Navigate to={"/"} />} />
        </Route>
      </Routes>
      {/* <Footer /> */}
    </>
  );
};

export default App;
